import { useState, useRef } from "react";
import logo from './logo.png';
import "firebase/firestore";
import { useFirestoreDoc, useFirestoreDocData, useFirestore } from 'reactfire';
import { Bars } from "@agney/react-loading"
import { useParams } from "react-router-dom";

import './Registration.css';

export function Registration() {
  const [ userInputs, setUserInputs ] = useState([<></>]);
  const [ userCount, setUserCount ] = useState(2);
  const [ name, setName ] = useState("");
  const [ phone, setPhone ] = useState("");
  const [ otherNames, setOtherNames ] = useState([]) as any;
  const [ isSaving, setIsSaving ] = useState(false);
  const [ isFinished, setIsFinished ] = useState(false);
  const [ isLoading, setIsLoading ] = useState(null) as any;
  const [ phoneError, setPhoneError ] = useState(false);
  const [ nameError, setNameError ] = useState(false);
  const [ error, setError ] = useState("")
  const [ gdprError, setGdprError ] = useState(false);
  const { siteid } = useParams() as any;
  const nameInput = useRef(null) as any;
  const phoneInput = useRef(null) as any;
  const gdprInput = useRef(null) as any;
  const userRef = useFirestore().collection(siteid);
  const doc = useFirestoreDoc(userRef.doc("_root")) as any;
  const docData = useFirestoreDocData(userRef.doc("_root")) as any;
  function removeUserInput() {
    let _i = userInputs.filter((el: any) => {
      return el;
    })
    _i.splice(-1, 1)
    let _g = otherNames.filter((el: any) => {
      return el;
    })
    _g.splice(-1, 1);
    setOtherNames(_g);
    setUserInputs(_i);
    setUserCount(userCount-1);
  }
  function checkInputs() {
    if (nameInput != null && phoneInput != null && gdprInput != null) {
      let _nameError = false;
      let _phoneError = false;
      let _gdprError = false;
      let _otherNamesError = false;
      if (nameInput.current.length < 3 || !nameInput.current.value.includes(" ")) {
        setNameError(true)
        _nameError = true;
      }
      else {
        setNameError(false)
      }
      if (phoneInput.current.value.toString().length < 8) {
        setPhoneError(true)
        _phoneError = true;
      }
      else {
        setPhoneError(false)
      }
      if (gdprInput.current.checked === false) {
        _gdprError = true;
        setGdprError(true);
      }
      else {
        setGdprError(false);
      }
      if (otherNames.includes(undefined)) {
        _otherNamesError = true;
      }
      if (_gdprError === true) {
        setError("Du må godkjenne vilkårene.")
      }
      if (_otherNamesError) {
        setError("Ett av person-feltene dine er tomme.")
      }
      if (_phoneError === true && _nameError === true) {
        setError("Du må skrive inn fullt for- og etternavn, samt telefonnummer.")
      }
      else if (_nameError === true) {
        setError("Du må skrive inn fullt for- og etternavn.")
      }
      else if (_phoneError === true) {
        setError("Du må skrive inn hele telefonnummeret ditt.")
      }
      
      return (!_phoneError && !_nameError && !_gdprError && !_otherNamesError)
    }
    else return false;
  }
  function saveInput() {
    if (checkInputs() === true) {
    setIsSaving(true);
    userRef.add({
      name,
      phone,
      otherNames
    }).then(() => {
      setIsFinished(true);
    })
    }
  }
  function addUserInput() {
    setUserCount(userCount+1);
    setUserInputs(userInputs.concat(userInput(userCount,
      (id: any, value: any) => {
        let _n = otherNames as any;
        _n.splice(id-2, 0);
        _n[id-2] = value;
        setOtherNames(_n);
      },
      otherNames[userCount-2]
    )));
  }
  if ((doc.status === "loading" || docData.status === "loading") && isLoading !== true) {
    setIsLoading(true);
  }
  if (doc.status === "success" && docData.status === "success" && isLoading !== false) {
    setIsLoading(false);
  }
  return (
    <div className="App">
      <header className="header">
        <img src={logo} className="logo" alt="logo" />
      </header>
      <div className="container">
      <div className="card">
        {     
        (isSaving === false && isLoading === false && docData.data !== undefined) ? <>
        <h1>Registrer konsertbesøket ditt</h1>
        <form autoComplete="off" className="form-wrapper">
        <label htmlFor="area">
          Område
        </label>
        <input id="site" className="cs-input disabled" disabled value={(docData.data === undefined ? "" : docData.data.name)}/>
        <div className="form-group">
      <label className={(nameError) ? "is-red" : ""} htmlFor="name">
        For- og etternavn
      </label>
      <input id="name" ref={nameInput} autoComplete="off" className={"cs-input"} placeholder="Ola Nordmann" onChange={e => setName(e.target.value)} value={name} />
      </div>
        <label htmlFor="phone" className={(phoneError) ? "is-red" : ""}>Telefonnummer</label>
        <input id="phone" autoComplete="off" type="number" ref={phoneInput} className="cs-input" placeholder="12345678" onChange={e => setPhone(e.target.value)} value={phone}/>
        {userInputs}
        { (userCount > 2) ? <span className="close-icon fade-in" onClick={removeUserInput}></span> : <></> }
        </form>
        <button className="cs-button small" onClick={addUserInput}><span className="cs-plus">+</span> Legg til person</button>
        <input ref={gdprInput} className="cs-checkbox" type="checkbox" id="gdpr-accept" /><label htmlFor="gdpr-accept" className={`cs-checkbox-label ${(gdprError === true) ? "is-red" : ""}`}>Jeg godtar at navn og telefonnummer blir lagret i opptil 3 uker og kan bli delt med Canal Street AS og norske helsemyndigheter.</label>
        <p className="cs-error">{error}</p>
        <button className="cs-button wide" onClick={saveInput}>Send inn</button></>
        :
        (doc.data !== undefined && doc.data.exists === false && isSaving === false && isLoading === false) ? <><h1>Her var det ikke mye!</h1><p>Det ser ikke ut til at vi skjønner helt hvor du vil hen. Har du skrevet/scannet riktig?</p></>
        :
        (isFinished === false) ?
        <div className="loading">
        <Bars/>
        </div>
        : 
        <>
          <h1>Takk for at du registrerte deg!</h1>
          <p>Informasjonen er sendt inn. </p>
          <p>Du kan nå lukke denne siden. Kos deg på festival!</p>
        </>
        }
      </div>
    </div>
    </div>
  );
}


function userInput(count?: number, onChange?: any, value?: any): any {
  return(
    <div className="form-group">
      <label htmlFor="name" className={(count) ? "fade-in" : ""}>
        For- og etternavn { (count) ? `person ${count}` : ""}
      </label>
      <input autoComplete="off" id={(count) ? `name${count}` : "name"} className={(count) ? "cs-input fade-in" : "cs-input"} placeholder="Ola Nordmann"  onChange={e => onChange(count, e.target.value)} value={value}/>
      </div>
  )
}
