import './Registration.css';
export function Home() {
    return(
        <div className="App">
        <div className="container">
        <div className="card">
            <h1>Her finnes det lite.</h1>
            <p>Du må scanne en QR-kode som tilhører den kohorten du er i, eller skrive inn nettadressen som står under QR-koden.</p>
        </div>
        </div>
        </div>
    )
}