import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { FirebaseAppProvider } from "reactfire";

const firebaseConfig = {
  apiKey: "AIzaSyCd--P_DrwKCDEel5PBMC4yubsn4upFZIw",
  authDomain: "canalstreet-corona.firebaseapp.com",
  projectId: "canalstreet-corona",
  storageBucket: "canalstreet-corona.appspot.com",
  messagingSenderId: "737694994888",
  appId: "1:737694994888:web:fe1544e9491456922970fd"
};

ReactDOM.render(
  <React.StrictMode>
    <FirebaseAppProvider firebaseConfig={firebaseConfig}>
    <App />
    </FirebaseAppProvider>
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
