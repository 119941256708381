import React from "react";
import {
    BrowserRouter as Router,
    Switch,
    Route
} from "react-router-dom";
import { Registration } from "./Registration";
import { Home } from "./Home";
function App() {
    return(
        <Router>
            <Switch>
                <Route path="/" exact component={Home}></Route>
                <Route path="/:siteid" component={Registration}></Route>
            </Switch>
        </Router>
    )
}

export default App;